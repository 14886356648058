import { icons } from "../../assets/icons/Icons";
import { path } from "../../utils/path";

export const navigations = [
  {
    title: "Dashboard",
    type: "item",
    url: path.root,
    Icon: icons.DashboardIcon,
  },
  {
    title: "iCart Management",
    type: "item",
    url: path.management.url,
    Icon: icons.LocationIcon,
  },
  {
    title: "Concepts & Creators",
    type: "item",
    url: path.conceptAndCreators.url,
    Icon: icons.BookIcon,
  },
  {
    title: "Applications",
    type: "item",
    url: "/applications",
    Icon: icons.AnalyticsIcon,
  },
  {
    title: "Businesses",
    type: "item",
    url: path.business.url,
    Icon: icons.BusinessIcon,
  },
  {
    title: "Users & Accounts",
    type: "item",
    url: path.accounts.url,
    Icon: icons.AccountIcon,
  },
  {
    title: "Inventory",
    type: "item",
    url: path.inventory.url,
    Icon: icons.InventoryIcon,
  },
  {
    title: "Materials",
    type: "item",
    url: path.materials.url,
    Icon: icons.InventoryIcon,
  },
  {
    title: "Sales",
    type: "item",
    url: path.sales.url,
    Icon: icons.SalesIcon,
  },
  {
    title: "Wallet",
    type: "item",
    url: path.wallet.url,
    Icon: icons.OnlineOrdersIcon,
  },
  {
    title: "Support",
    type: "item",
    url: path.support.url,
    Icon: icons.SupportIcon,
  },
  {
    title: "Analytics",
    type: "item",
    url: "/analytics",
    Icon: icons.AnalyticsIcon,
  },

  {
    title: "Messages",
    type: "item",
    url: "/messages",
    Icon: icons.MessagesIcon,
  },
  {
    title: "Orders",
    type: "orders",
    url: "/8",
    Icon: icons.OrdersIcon,
  },
  {
    title: "Online Orders",
    type: "item",
    Icon: icons.OnlineOrdersIcon,
    url: path.iCartOperating.onlineOrders.url + `?all=true`,
  },
  {
    title: "Pre-Orders",
    type: "item",
    url: "/pre-orders",
    Icon: icons.PreOrdersIcon,
  },
  {
    title: "Prep Room",
    type: "item",
    url: path.prepRoom.url,
    Icon: icons.KitchenIcon,
  },
  {
    title: "Store",
    type: "item",
    url: path.store.url,
    Icon: icons.StoreIcon,
  },
  {
    title: "iCart Operating",
    type: "item",
    url: path.iCartOperating.url,
    Icon: icons.StoreIcon,
  },
  {
    title: "Suppliers",
    type: "item",
    url: path.suppliers.url,
    Icon: icons.SuppliersIcon,
  },
  {
    title: "Creators",
    type: "item",
    url: path.creators.url,
    Icon: icons.SubmissionIcon,
  },
  {
    title: "Settings",
    type: "item",
    url: "/settings",
    Icon: icons.SettingsIcon,
  },
  {
    title: "Logout",
    type: "item",
    url: "/logout",
    Icon: icons.LogoutIcon,
  },
];
