import Dashboard from "../pages/app/applications/Dashboard";
import { Route, Routes } from "react-router-dom";
import { path } from "../utils/path";
import ApplicationDetails from "../pages/app/applications/ApplicationDetails";

function Applications(props) {
  return (
    <Routes>
      <Route path={path.root} element={<Dashboard />} />
      <Route path={path.details} element={<ApplicationDetails />} />
    </Routes>
  );
}

export default Applications;
