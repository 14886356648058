export function capitalizeFirstLetter(string) {
  if (!string?.length) return string; // handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getRandomImage = () =>
  `https://randomuser.me/api/portraits/men/${(Math.random() * 10).toFixed(
    0
  )}.jpg`;

export const getDigits = (string, count = 6) =>
  string.split("").reverse().join("").slice(0, count).toUpperCase();
