import urls from "../../api/urls";
import useAPI from "./useAPI";
import { useState } from "react";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";

function useApplications(props) {
  const [pagination, setPagination] = useState({ total: 0 });
  const [applications, setApplications] = useState([]);
  const { loading, post, get, patch } = useAPI();

  const getApplications = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.applications.baseUrl, { pageNumber, limit })
    );
    if (error) return;
    setApplications(data.applications);
    setPagination(data.pagination);
  };

  const acceptApplication = async (id, fdata, cb) => {
    const { data, error, message } = await patch(
      urls.applications.baseUrl + `/${id}`,
      fdata
    );
    if (error) return;
    toast.success(message || data.message);
    if (typeof cb === "function") cb();
  };

  return {
    loading,
    applications,
    pagination,
    getApplications,
    acceptApplication,
  };
}
export default useApplications;
