import { useContext, useEffect, useState } from "react";
import useInventory from "../../hooks/api/useInventory";
import AppContext from "../../contexts/AppContext";
import useMenu from "../../hooks/api/useMenu";
import { COLORS } from "../../utils/colors";
import Collapsable from "../Collapsable";
import Revenue from "../charts/Revenue";
import Button from "../Button";
import Input from "../Input";
import CheckBox from "../CheckBox";
import { toast } from "react-toastify";

const getMenuCost = (recipe, ingredient, extras, prices) => {
  const materials = recipe
    .filter((_) => _.conceptIngredient)
    .map((_) => [_.conceptIngredient.material, _.quantity]);
  // console.log(
  //   recipe.filter((_) => _.conceptExtra).map((_) => ingredient.recipe)
  // );

  const _extras = recipe
    .filter((_) => _.conceptExtra)
    .map((_) => [_.conceptExtra._id, _.quantity]);
  if (_extras.length) materials.push(..._extras);

  return materials.reduce(
    (c, _) => c + (prices.find((p) => p.material === _[0])?.cost || 0) * _[1],
    0
  );
};

function Approval({ concept }) {
  const [selectedPackaging, setSelectedPackaging] = useState(
    concept?.concept?.packaging
  );
  const [structuredData, setStructuredData] = useState([]);
  const [packagingPrice, setPackagingPrice] = useState(0);
  const [vatInclusive, setVatInclusive] = useState(true);
  const [inPackage, setPackageNumber] = useState(1);
  const { currentCity, formatCurrency } = useContext(AppContext);
  const [markup, setMarkup] = useState(150);
  const [prices, setPrices] = useState([]);
  const { getCost, loading: menuloading } = useMenu();
  const {
    getMaterialPrices,
    searchMaterial,
    materials,
    loading: materialsLoading,
  } = useInventory();

  const getPrices = async () => {
    setPrices([]);
    const opt = [];

    for (let i = 0; i < concept.ingredients.length; i++) {
      const item = concept.ingredients[i];
      let price = null;
      if (item.material)
        price = await getMaterialPrices(item.material?._id, currentCity._id);
      else toast.warn(`${item.name} not found in materials`);
      if (price)
        opt.push({ ...price, material: item.material._id, name: item.name });
      else toast.warn(`${item.name} price not found in materials`);
    }
    for (let i = 0; i < concept.extras.length; i++) {
      const extra = concept.extras[i];
      const price = await getCost(currentCity._id, null, extra._id);
      // console.log("=========================+");
      opt.push({
        ...price,
        name: extra.name,
        material: extra._id,
        unitOfMeasure: extra.unitOfMeasure,
      });
      // console.log("=========================+");
    }

    setPrices(opt);
  };

  const getPackagePrice = async () => {
    if (!selectedPackaging || selectedPackaging?.toLowerCase() === "packaging")
      return setPackagingPrice(0);
    const p = await getMaterialPrices(selectedPackaging, currentCity._id);
    if (p) setPackagingPrice(p.cost);
    else setPackagingPrice(0);
  };

  useEffect(() => {
    if (currentCity) {
      getPrices();
    }

    // eslint-disable-next-line
  }, [currentCity]);

  useEffect(() => {
    if (currentCity && selectedPackaging) getPackagePrice();

    // eslint-disable-next-line
  }, [currentCity, selectedPackaging]);

  useEffect(() => {
    searchMaterial("packaging");

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (prices.length && inPackage) {
      setStructuredData(
        concept?.menu?.map((_) => {
          const cost =
            (getMenuCost(
              _.recipe,
              concept.ingredients,
              concept.extras,
              prices
            ) /
              _.output) *
            inPackage;
          return {
            ..._,
            cost,
            recipe: _.recipe.map((xx) => {
              const itemCost = (
                (prices.find(
                  (p) =>
                    p.material === xx.conceptIngredient?.material ||
                    p.material === xx.conceptExtra?._id
                )?.cost || 0) * xx.quantity
              ).toFixed(2);
              return {
                ...xx,
                itemCost,
                percentageCost: ((itemCost / cost) * 100).toFixed(2),
              };
            }),
          };
        })
      );
    }

    // eslint-disable-next-line
  }, [prices, inPackage, packagingPrice]);

  return (
    <div>
      <Collapsable
        color="var(--yellow-tints-5)"
        showInfo={false}
        className="fs-14 d-block text-brown f-700"
        title={`Raw Material Prices`}
      >
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Price/Unit</th>
            </tr>
          </thead>
          <tbody>
            {prices.map((_, idx) => (
              <tr key={_._id}>
                <td>{_.name}</td>
                <td>
                  {formatCurrency((_.cost || 0).toFixed(2))}/
                  {_.unitOfMeasure ||
                    concept.ingredients.find(
                      (xx) => xx.material._id === _.material
                    ).unitOfMeasure}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Collapsable>
      <br />
      <Collapsable
        color="var(--yellow-tints-5)"
        showInfo={false}
        className="fs-14 d-block text-brown f-700"
        title={`Menu Cost & Prices`}
      >
        <div className="inputs">
          <Input type="number" onChange={setMarkup} value={markup} />
          <Input
            type="number in package"
            onChange={setPackageNumber}
            value={inPackage}
          />
        </div>
        <br />
        <Input
          select={true}
          value={selectedPackaging}
          onChange={setSelectedPackaging}
          placeholder="Packaging"
          options={materials.map((_) => ({ label: _.name, value: _._id }))}
        />
        <br />
        <div className="d-flex align-center gap-10">
          <span className="text-brown d-block">VAT Inclusive</span>
          <CheckBox value={vatInclusive} onChange={setVatInclusive} />
        </div>
        <br />
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Total Cost</th>
              <th>{markup}% Markup</th>
              <th>Package Sales Price</th>
              <th>Percentage Cost</th>
              {/* <th>Package Price</th> */}
            </tr>
          </thead>
          <tbody>
            {structuredData.map((_, idx) => {
              const cost = _.cost;
              const _markup = (markup / 100) * cost;
              const price = _markup + cost;
              const percentage = (cost / price) * 100;
              return (
                <tr key={_._id}>
                  <td>{_.name}</td>
                  <td>{formatCurrency(cost.toFixed(2))}</td>
                  <td>{formatCurrency(_markup.toFixed(2))}</td>
                  <td>
                    {formatCurrency(
                      (
                        price +
                        packagingPrice +
                        (vatInclusive ? currentCity?.country?.vat / 100 : 0) *
                          price
                      ).toFixed(2)
                    )}
                  </td>
                  <td>{(isNaN(percentage) ? 0 : percentage).toFixed(2)}%</td>
                  {/* <td>{formatCurrency(packagingPrice)}</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Collapsable>
      <Collapsable
        color="var(--yellow-tints-5)"
        showInfo={false}
        className="fs-14 d-block text-brown f-700"
        title={`Cost/Recipe`}
      >
        {structuredData.map((_, idx) => (
          <Collapsable key={_.id} title={_.name}>
            <Collapsable title="Raw Materials Cost">
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Qauntity</th>
                    <th>Cost</th>
                    <th>Instruction</th>
                    <th>Percentage Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {_.recipe.map((xx) => {
                    const i = xx.conceptIngredient || xx.conceptExtra;
                    return (
                      <tr key={xx._id}>
                        <td>{i.name}</td>
                        <td>
                          {xx.quantity}
                          {i?.unitOfMeasure}
                        </td>
                        <td>{formatCurrency(xx.itemCost)}</td>
                        <td style={{ maxWidth: 150, whiteSpace: "normal" }}>
                          {xx.instruction}
                        </td>
                        <td>{xx.percentageCost}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Collapsable>
            <Collapsable title="Cost Graph">
              {/*  {
                label: "",
                data: labels
                  .map((a) => a.slice(0, 3))
                  .map(() => Math.random() * 1000),
                borderColor: COLORS.MAIN.BLUE,
                backgroundColor: COLORS.MAIN.BLUE,
              } */}
              <Revenue
                data={{
                  labels: [...concept?.ingredients, ...concept?.extras].map(
                    (_) => _.name
                  ),
                  datasets: [
                    {
                      label: "Cost",
                      data: [...concept?.ingredients, ...concept?.extras].map(
                        (ing) => {
                          return _.recipe
                            .filter(
                              (rp) =>
                                rp.conceptIngredient?._id === ing._id ||
                                rp.conceptExtra?._id === ing._id
                            )
                            .reduce(
                              (prv, c) => prv + parseFloat(c.percentageCost),
                              0
                            );
                        }
                      ),
                      borderColor: COLORS.MAIN.BLUE,
                      backgroundColor: COLORS.MAIN.BLUE,
                    },
                  ],
                }}
                labels={concept?.ingredients?.map((_) => _.name)}
              />
            </Collapsable>
          </Collapsable>
        ))}
      </Collapsable>
      <br />
      <Button
        loading={materialsLoading || menuloading}
        title="Save & Continue"
      />
    </div>
  );
}

export default Approval;
//0.031
