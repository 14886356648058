import { useEffect } from "react";
import useApplications from "../../../hooks/api/useApplications";
import usePagination from "../../../hooks/usePagination";
import Table from "../../../components/table/Table";
import WithImage from "../../../components/table/WithImage";
import WithLink from "../../../components/table/WithLink";
import { path } from "../../../utils/path";
import { getDigits } from "../../../utils/string";
import ID from "../../../components/table/ID";
import { formatDateTime } from "../../../utils/date";

const tableHead = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "ID",
    target: "*",
    render: (v) => (
      <ID
        state={v}
        link={path.applications.view.url + `?id=${v._id}`}
        label="View Item"
        id={getDigits(v._id)}
      />
    ),
  },
  {
    title: "Application Name",
    target: "name",
  },
  {
    title: "User",
    target: "customerInformation",
    render: (info) => <WithImage text={`${info.firstName} ${info.lastName}`} />,
  },
  {
    title: "Phone Number",
    target: "customerInformation.phoneNumber",
    render: (phoneNumber) => (
      <WithLink link={`tel:${phoneNumber}`} text={phoneNumber} />
    ),
  },
  {
    title: "Total Score",
    target: "response",
    render: (response) => {
      const points = response.reduce((p, c) => p + c.score, 0);
      const className =
        points < 40
          ? "danger"
          : points < 60
          ? "warn"
          : points < 80
          ? "info"
          : "success";
      return (
        <span>
          <strong className={`${className}-text`}>{points}</strong> Points{" "}
          <strong className={`${className}-text`}>
            (
            {points < 40
              ? "Non-Ideal Customer"
              : points < 60
              ? "Warm Lead"
              : points < 80
              ? "Qualified Customer"
              : "Priority Customer"}
            )
          </strong>
        </span>
      );
    },
  },
  {
    title: "Date",
    target: "createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
];

function Dashboard(props) {
  const { applications, getApplications, loading, pagination } =
    useApplications();
  const { limit, pageNumber, setPageNumber } = usePagination({
    initialLimit: 5,
  });

  useEffect(() => {
    getApplications(pageNumber, limit);
  }, [pageNumber, limit]);

  return (
    <div>
      <Table
        loading={loading}
        title="Applications"
        data={applications}
        limit={limit}
        onPaginate={setPageNumber}
        head={tableHead}
        totalCount={pagination.total}
      />
    </div>
  );
}

export default Dashboard;
