import { toast } from "react-toastify";
import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";

function useSettings(props) {
  const [settings, setSettings] = useState({});
  const { loading, get, put } = useAPI();

  const getSettings = async () => {
    const { data, error } = await get(urls.configs.settings);
    if (error) return;
    setSettings(data.settings);
  };

  const updateSettings = async (fdata, cb) => {
    const { error, message } = await put(urls.configs.settings, fdata);
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  return {
    loading,
    settings,
    getSettings,
    updateSettings,
  };
}
export default useSettings;
