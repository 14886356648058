import { useLocation } from "react-router-dom";
import Button from "../../../components/Button";
import { Form, FormInput, Submit } from "../../../components/form";
import Image from "../../../components/Image";
import Modal from "../../../components/Modal";
import NotFound from "../../../components/NotFound";
import useModal from "../../../hooks/useModal";
import Status from "../../../components/Status";
import { baseStatuses } from "../../../utils/statuses";
import { formatDateTime } from "../../../utils/date";
import StarsReview from "../../../components/StarsReview";
import { useState } from "react";
import Tab from "../../../components/Tab";
import MessagesIcon from "../../../assets/icons/MessagesIcon";
import useApplications from "../../../hooks/api/useApplications";

const PInfo = ({ state }) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>-</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>{`${state.customerInformation.firstName} ${state.customerInformation.lastName}`}</td>
          </tr>
          <tr>
            <td>
              <strong>Email</strong>
            </td>
            <td>{state.customerInformation.email}</td>
          </tr>
          <tr>
            <td>
              <strong>Phone Number</strong>
            </td>
            <td>{state.customerInformation.phoneNumber}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Questionaire = ({ state }) => {
  const totalScore = state.response.reduce((p, c) => p + c.score, 0);
  const className =
    totalScore < 40
      ? "danger"
      : totalScore < 60
      ? "warn"
      : totalScore < 80
      ? "info"
      : "success";
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {state.response.slice(4).map((_) => {
            return (
              <tr key={_._id}>
                <td style={{ maxWidth: "40vw", whiteSpace: "normal" }}>
                  {_.question}
                </td>
                <td style={{ maxWidth: "20vw", whiteSpace: "normal" }}>
                  {_.answer}
                </td>
                <td style={{ maxWidth: 150, whiteSpace: "normal" }}>
                  {_.score}
                </td>
              </tr>
            );
          })}
          <tr>
            <td></td>
            <td>
              <strong>Total Score: </strong>
            </td>
            <td>
              <strong className={`${className}-text`}>{totalScore}</strong>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <strong>Class: </strong>
            </td>
            <td>
              <strong className={`${className}-text`}>
                {totalScore < 40
                  ? "Non-Ideal Customer"
                  : totalScore < 60
                  ? "Warm Lead"
                  : totalScore < 80
                  ? "Qualified Customer"
                  : "Priority Customer"}
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const tabs = [
  {
    title: "Questionaire",
    Element: Questionaire,
  },
  {
    title: "Personal Information",
    Element: PInfo,
  },
];

function ApplicationDetails(props) {
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const { openModal, closeModal, isOpened } = useModal();
  const { state } = useLocation();
  const { Element } = currentTab;
  const { acceptApplication, loading } = useApplications();

  if (!state) return <NotFound />;
  if (state?.customerInformation)
    return (
      <div>
        <Modal isVisible={isOpened}>
          <h2 className="fs-24 raleway f-700 text-primary">
            Acceept Application
          </h2>
          <br />
          <Form
            onSubmit={(values) => {
              acceptApplication(state._id, values, closeModal);
            }}
            initialValues={{
              applicationFee: "",
              numberOfPayment: 1,
              message: "",
            }}
          >
            <FormInput name="message" textarea placeholder="Message" />
            <br />
            <div className="inputs">
              <FormInput
                type="number"
                name="applicationFee"
                placeholder="Payment"
              />
              <FormInput
                type="number"
                name="numberOfPayment"
                placeholder="Installment"
              />
            </div>
            <br />
            <div className="d-flex gap-10">
              <Button onClick={closeModal} width={100} title="Cancel" outline />
              <Submit loading={loading} title="Continue" />
            </div>
          </Form>
        </Modal>
        <div className="d-flex v-user-info">
          <div className="info-1">
            <Image
              className="profile-img"
              src={state.customerInformation.profileImage}
            />
            <br />
            <br />
            <span className="fs-11 text-grey d-block f-400">
              Date Submitted
            </span>
            <h3 className="text-brown">
              {formatDateTime(state.createdAt).dateTime}
            </h3>
            <br />
            <span className="fs-11 text-grey d-block f-400">Last Updated</span>
            <h3 className="text-brown">
              {formatDateTime(state.updatedAt).dateTime}
            </h3>
            <br />
          </div>
          <div className="info-2 full-flex">
            <h2 className="text-brown fs-32">
              {state.customerInformation.firstName}{" "}
              {state.customerInformation.lastName}
            </h2>
            <Status
              light
              text={state.customerInformation.role}
              className={baseStatuses[state.customerInformation.role]}
            />
            <br />
            <br />
            <span className="fs-14 d-block text-brown f-700">Score</span>
            <br />
            <div className="d-flex justify-between align-center">
              <div className="d-flex gap-10 align-center">
                <h2 className="text-brown">
                  {state.response.reduce((p, c) => p + c.score, 0)}
                </h2>
                <StarsReview
                  showNumber={false}
                  defaultRating={Math.round(
                    (state.response.reduce((p, c) => p + c.score, 0) / 100) * 5
                  )}
                />
              </div>
              <div>
                {/* <span className="fs-14 d-block text-brown f-700">
                  Change Account Status
                </span>
                <div
                  style={{ marginTop: 5 }}
                  className="d-flex gap-10 align-center"
                >
                  {Object.keys(accountStatuses).map((_) => (
                    <Status
                      onClick={() =>
                        updateUser(state.customerInformation._id, { status: _ })
                      }
                      style={{
                        borderWidth: 1,
                        borderRadius: 0,
                        borderBottomWidth: 1,
                      }}
                      text={_}
                      className={accountStatuses[_]}
                      light={state.customerInformation.status !== _}
                    />
                  ))}
                </div> */}
              </div>
            </div>
            <br />
            <div>
              <Button
                onClick={openModal}
                outline
                width={200}
                lSvg={<MessagesIcon color="var(--main-yellow)" />}
                title="Accept Application"
              />
            </div>
            <br />
            <Tab
              autoSelect
              className="tabs-noborder"
              tabs={tabs}
              onSwitch={setCurrentTab}
            />
            <br />
            <br />
            {<Element state={state} />}
          </div>
        </div>
      </div>
    );
}

export default ApplicationDetails;
